import React, { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, CssBaseline, LinearProgress, responsiveFontSizes, ThemeProvider, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Nav } from './components/Nav';
import './App.css';

const Home = lazy(() => import('./pages/Home'));

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  const theme = useMemo(() => responsiveFontSizes(
    createTheme({
      palette: { mode: prefersDarkMode ? 'dark' : 'light' }
    })
  ), [prefersDarkMode]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/">
                <Route index  element={<Home />} />
                <Route path="/:name"  element={<Home />} />
              </Route>
             
            </Routes>
          </Suspense>
        </>
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
